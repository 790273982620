<script setup lang="ts">
  import type { FormKitSchemaNode } from '@formkit/core'
  import { useWithout } from '#imports'
  import removeEmptyEntries from '~/utils/remove-empty-entries'
  import { formatCurrency } from '~/utils/currency'
  import { formatDate } from '~/utils/date'

  const props = defineProps<{
    schema: FormKitSchemaNode[]
  }>()

  const emit = defineEmits(['update'])

  const route = useRoute()
  const router = useRouter()

  const data = computed(() => {
    const entries = props.schema.map(({ name, $formkit, options }) => {
      return [name, route.query?.[name]]
    })
    return removeEmptyEntries(useFromPairs(entries))
  })

  const hasFilter = computed(() => {
    return !isEmpty(data.value)
  })

  function getOptionLabel (fieldName, option) {
    const field = props.schema.find(({ name }) => name === fieldName)
    const opt = field?.options?.find(({ value }) => value === option)
    return opt?.label
  }

  function clearFilter (fieldName = null, option = null) {
    if (option && isArray(data.value[fieldName])) {
      router.replace({
        query: {
          ...useOmit(route.query, 'page'),
          [fieldName]: useWithout(data.value[fieldName], option),
        }
      })
    } else if (fieldName) {
      router.replace({
        query: useOmit(route.query, [fieldName, 'page'])
      })
    } else {
      router.replace({
        query: useOmit(route.query, [...useKeys(data.value), 'page'])
      })
    }
    emit('update')
  }
</script>

<template>
  <app-card
    v-if="hasFilter"
    class="filter-overview">
    <div class="filter-overview__layout">
      <span class="filter-overview__label u-mr--400 u-ff--mono u-fs--200">
        použité filtry:
      </span>
      <div class="filter-overview__items">
        <template v-for="field in schema">
          <template v-if="(field['$formkit'] === 'checkbox' || field['$formkit'] === 'radio') && data[field.name]">
            <template v-if="Array.isArray(data[field.name])">
              <template v-for="value in data[field.name]">
                <app-chip
                  v-if="value"
                  v-motion-pop
                  :key="`${field.name}_${value}`"
                  class="filter-overview__item filter-overview__item--checkbox"
                  @dismiss="clearFilter(field.name, value)"
                  dismissable>
                  {{ getOptionLabel(field.name, value) }}
                </app-chip>
              </template>
            </template>
            <template v-else-if="!field?.options">
              <app-chip
                v-motion-pop
                :key="`${field.name}`"
                class="filter-overview__item"
                @dismiss="clearFilter(field.name)"
                dismissable>
                {{ field.label }}
              </app-chip>
            </template>
            <template v-else>
              <app-chip
                v-motion-pop
                :key="`${field.name}_${data[field.name]}`"
                class="filter-overview__item"
                @dismiss="clearFilter(field.name, data[field.name])"
                dismissable>
                {{ getOptionLabel(field.name, data[field.name]) }}
              </app-chip>
            </template>
          </template>

          <template v-else-if="field['$formkit'] === 'select' && data[field.name]">
            <app-chip
              v-motion-pop
              :key="`${field.name}_${data[field.name]}`"
              class="filter-overview__item"
              @dismiss="clearFilter(field.name, data[field.name])"
              dismissable>
              {{ getOptionLabel(field.name, data[field.name]) }}
            </app-chip>
          </template>
          <template v-else-if="field['$formkit'] === 'rangeSlider' && data[field.name]">
            <app-chip
              v-if="data[field.name][0] != field['min'] || data[field.name][1] != field['max']"
              v-motion-pop
              :key="`${field.name}`"
              class="filter-overview__item"
              @dismiss="clearFilter(field.name)"
              dismissable>
              {{ data[field.name][0] != field['min'] ? `od ${formatCurrency(data[field.name][0])}` : '' }}
              {{ data[field.name][1] != field['max'] ? `do ${formatCurrency(data[field.name][1])}` : '' }}
            </app-chip>
          </template>
          <template v-else-if="field['$formkit'] === 'dateTimePicker' || field['$formkit'] === 'date' && data[field.name]">
            <app-chip
              v-motion-pop
              :key="`${field.name}`"
              class="filter-overview__item"
              @dismiss="clearFilter(field.name)"
              dismissable>
              <template v-if="field.range">
                {{ data[field.name][0] ? `${formatDate(data[field.name][0])}` : '' }}
                {{ data[field.name][1] ? ` - ${formatDate(data[field.name][1])}` : '' }}
              </template>
              <template v-else>
                {{ field.id === 'date_from' ? 'Od ' : field.id === 'date_to' ? 'Do ' : '' }}{{ formatDate(data[field.name]) }}
              </template>
            </app-chip>
          </template>
          <template v-else>
            <app-chip
              v-if="data[field.name]"
              v-motion-pop
              :key="field.name"
              class="filter-overview__item"
              @dismiss="clearFilter(field.name)"
              dismissable>
              {{ data[field.name] }}
            </app-chip>
          </template>
        </template>
      </div>
      <app-button
        class="filter-overview__clear"
        text
        @click="clearFilter">
        vyčistit filtry
      </app-button>
    </div>
  </app-card>
</template>

<style scoped>
  .filter-overview__layout {
    display: flex;
    gap: var(--gap-400);
    flex-direction: column;
    align-items: flex-start;
    @media (--md) {
      flex-direction: row;
      align-items: center;
    }
  }

  .filter-overview__items {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-200);
  }

  .filter-overview__item:deep(.app-chip__text) {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filter-overview__label {
    white-space: nowrap;
  }

  .filter-overview__clear {
    @media (--md) {
      margin-left: auto;
    }
  }
</style>
